import { useMemo } from 'react'
import NextLink from 'next/link'
import {
  Box,
  Image,
  ImageProps,
  LinkProps,
  LinkOverlay,
  LinkBox,
  LinkBoxProps,
  Text,
  GridItem,
  GridItemProps,
} from '@chakra-ui/react'
import { ThemeScheme } from './types'
import { imageOptimization } from '../general/components/image-optimization'

export interface TeamLogoCardProps {
  gridItemProps?: GridItemProps
  root?: Omit<LinkBoxProps, 'children'>
  theme?: ThemeScheme
  label?: {
    content?: string
    font?: {
      size?: string
      family?: string
    }
  }
  link?: LinkProps
  image?: {
    src?: string
    alt?: string
  } & ImageProps
  alignment?: string
}

export const TeamLogoCard = ({
  image,
  label,
  link,
  root,
  alignment,
  gridItemProps,
  theme = 'dark',
}: TeamLogoCardProps) => {
  const { font: titleFont } = label ?? {}

  const fontSize = titleFont?.size ?? '50'
  const fontStyleToken = `body-${fontSize}`

  const contentAlignment = useMemo(() => {
    switch (alignment) {
      case 'left':
        return 'flex-start'
      case 'right':
        return 'flex-end'
      default:
        return 'center'
    }
  }, [alignment])

  return (
    <GridItem justifySelf={contentAlignment} {...gridItemProps}>
      <LinkBox display="flex" flexDirection="column" layerStyle={theme} maxW={24} {...root}>
        <LinkOverlay href={link?.href} display="flex" flexDirection="column" alignItems={contentAlignment}>
          {image?.src && (
            <Box position="relative">
              <Image src={imageOptimization(image?.src)} alt={image?.alt} fit="contain" />
            </Box>
          )}
          <Box
            as={NextLink}
            href={link.href ?? '#'}
            _hover={{
              bg: 'none',
            }}
            _active={{
              bg: 'none',
            }}
            mt={3}
            w="full"
          >
            <Text align="center" textStyle={fontStyleToken}>
              {label?.content}
            </Text>
          </Box>
        </LinkOverlay>
      </LinkBox>
    </GridItem>
  )
}
