import React, { useEffect, useState } from 'react'
import { Grid, Box, Skeleton, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackGridComponent as ContentstackGridComponentProps } from '@Types/contentstack'
import { fontFamilies, sizes } from 'composable/chakra/figma-tokens'
import { ContentstackArticleCard } from './article-card'
import { ContentstackCoverCard } from './cover-card'
import { ContentstackTextCard } from './text-card'
import { calculateFontStyles } from './utils'
import useContentstack from '../hooks/useContentstack'

export const ContentstackGridComponent = ({
  columns,
  grid_gap,
  content,
  background_color,
  select_custom_background_color,
  grid_title,
  grid_eyebrow,
  grid_description,
  heading_alignment,
  title_font_family,
  title_font_size,
  horizontal_padding,
  vertical_padding,
  analyticsTrackingData,
}: ContentstackGridComponentProps) => {
  const [contentList, setContentList] = useState([])
  const { getEntryByUid, isLoading } = useContentstack()
  const isMobile = useBreakpointValue({ base: true, lg: false })

  useEffect(() => {
    getGridContent(content)
  }, [])

  const getGridContent = async (content: any) => {
    try {
      const itemsResults = content?.map(async (eachContent) => {
        return {
          contentType: eachContent._content_type_uid,
          data: await getEntryByUid(eachContent._content_type_uid, eachContent.uid),
        }
      })
      const results = await Promise.all(itemsResults)
      setContentList(results)
    } catch (err) {
      console.log('Error processing Mega Menu data', err)
    }
  }

  if (isLoading) return <Skeleton />

  if (!content || content.length === 0) return null

  const alignment: any = heading_alignment || 'center'
  const fontTitle: any = title_font_family || 'Libre Franklin'
  const fontSize: any = title_font_size || 400

  const {
    fontSize: titleFontSize,
    fontWeight: titleFontWeight,
    lineHeight: titleLineHeight,
  } = calculateFontStyles(fontSize)

  return (
    <Box
      paddingX={horizontal_padding}
      paddingY={vertical_padding}
      backgroundColor={background_color === 'Custom' ? select_custom_background_color : ''}
      data-promotion-name={analyticsTrackingData}
    >
      {grid_eyebrow && (
        <Box
          fontFamily={fontFamilies.primary}
          fontSize="14px"
          fontWeight={700}
          lineHeight="14px"
          letterSpacing="0.04em"
          textAlign={alignment}
          marginBottom={sizes[3]}
          tabIndex={0}
        >
          {grid_eyebrow}
        </Box>
      )}
      {grid_title && (
        <Box
          fontFamily={fontTitle}
          fontSize={titleFontSize}
          fontWeight={titleFontWeight}
          lineHeight={titleLineHeight}
          textAlign={alignment}
          marginBottom={sizes[3]}
          tabIndex={0}
        >
          {grid_title}
        </Box>
      )}
      {grid_description && (
        <Box
          fontFamily={fontFamilies.primary}
          fontSize="20px"
          fontWeight={400}
          lineHeight="24px"
          textAlign={alignment}
          marginBottom={sizes[9]}
          tabIndex={0}
        >
          {grid_description}
        </Box>
      )}
      <Grid
        gridGap={isMobile ? Math.max((parseInt(grid_gap) || 2) / 4, 5) : grid_gap || 2}
        alignItems="start"
        templateColumns={{
          base: '1fr',
          md: `repeat(${columns > 4 ? 4 : columns}, 1fr)`,
          lg: `repeat(${columns > 6 ? 6 : columns}, 1fr)`,
        }}
        templateRows={{
          base: `repeat(${columns}, 1fr)`,
          md: 'none',
          lg: 'none',
        }}
      >
        {contentList?.map((contentItem: any, index: number) => {
          switch (contentItem?.contentType) {
            case 'component_article_card': {
              return (
                <ContentstackArticleCard
                  key={contentItem?.data?.uid}
                  {...contentItem.data}
                  analyticsTrackingData={analyticsTrackingData}
                  relativeIndex={index + 1}
                />
              )
            }

            case 'component_cover_card': {
              return (
                <ContentstackCoverCard
                  key={contentItem.data.uid}
                  {...contentItem.data}
                  analyticsTrackingData={analyticsTrackingData}
                  relativeIndex={index + 1}
                />
              )
            }

            case 'component_text_card': {
              return (
                <ContentstackTextCard
                  key={contentItem.data.uid}
                  {...contentItem.data}
                  analyticsTrackingData={analyticsTrackingData}
                  relativeIndex={index + 1}
                />
              )
            }
          }
        })}
      </Grid>
    </Box>
  )
}
